import { cn } from "@peerigon/pupper/tailwind";
import { useLocale } from "next-intl";
import React, { useMemo, useState } from "react";
import { useBoolean, useInterval, useTimeoutFn } from "react-use";
import { FontStyle } from "src/__generated__/graphql";

// determines a starting point for the build up based on size
// => lower numbers don't have to do as much build up
const startLower = (i: number): number => {
	const magnitude = `${i}`.length;
	let lowered = i;

	if (magnitude === 1) {
		return lowered;
	}

	if (magnitude === 2 || magnitude === 3) {
		lowered /= 2;
	}

	if (magnitude >= 4) {
		lowered -= 2000;
	}

	return lowered > 0 ? Math.round(lowered) : 0;
};

type Props = {
	figure: number;
	visible?: boolean;
	fps?: number;
	duration?: number;
	delayMax?: number;
	fontStyle: FontStyle;
};

const FigureBuildUp: React.FC<Props> = ({
	figure,
	visible = false,
	duration = 2000,
	fps = 30,
	delayMax = 1000,
	fontStyle,
}) => {
	const locale = useLocale();

	// start with a lowered figure
	const startNumber = useMemo(() => startLower(figure), [figure]);
	const [currentFigure, setCurrentFigure] = useState(startNumber);

	// random delay
	const delay = useMemo(
		() => Math.round(Math.random() * delayMax),
		[delayMax],
	);
	const [delayed, toggleDelayed] = useBoolean(false);

	const [frameCount, setFrameCount] = useState(0);
	const totalFrames = Math.floor(duration / fps);

	// start animation after visible + delay, stop when target numbers are hit
	const done = currentFigure >= figure;
	const running = visible && delayed && !done;

	// delay
	useTimeoutFn(() => {
		toggleDelayed(true);
	}, delay);

	// main loop
	useInterval(
		() => {
			const percentage = frameCount / totalFrames;
			const interpolated =
				startNumber + percentage * (figure - startNumber);

			setCurrentFigure(interpolated);
			setFrameCount(frameCount + 1);
		},
		running ? fps : null,
	);

	const displayString = new Intl.NumberFormat(locale).format(
		Math.floor(currentFigure),
	);

	return (
		<span
			className={cn(
				"flex-grow",
				"transition-transform duration-200 ease-in",
				done ? "scale-100" : "scale-[0.9]",
				fontStyle.endsWith("italic") ? "italic" : undefined,
				fontStyle.startsWith("black") ? "font-bold" : "font-light",
			)}
		>
			{displayString}
		</span>
	);
};

export default FigureBuildUp;
