"use client";

import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { useImageGalleryScroll } from "src/components/sections/FourImagesSection/FourImagesSection.hooks";
import { getColorTheme } from "../../../styles/variables";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import { getImageAlt } from "../../server.helpers";
import BaseSection from "../BaseSection";
import type { FourImagesSectionItemFragment } from "src/__generated__/graphql";

export const FourImagesSectionFragment = graphql(`
	fragment FourImagesSectionItem on FourImagesSection {
		__typename
		id
		spacingTop
		spacingBottom
		upperLandscapeImage {
			...CmsImageFields
		}
		upperPortraitImage {
			...CmsImageFields
		}
		lowerPortraitImage {
			...CmsImageFields
		}
		lowerLandscapeImage {
			...CmsImageFields
		}
		galleryColorTheme: colorTheme
	}
`);

export const FourImagesSection: React.FC<{
	section: FragmentType<typeof FourImagesSectionFragment>;
}> = ({ section }) => {
	const {
		spacingTop,
		spacingBottom,
		upperLandscapeImage,
		upperPortraitImage,
		lowerLandscapeImage,
		lowerPortraitImage,
		galleryColorTheme,
	} = getFragmentData(FourImagesSectionFragment, section);

	const { marqueeRef, multiplier, marqueeScrollWidth } =
		useImageGalleryScroll();

	return (
		<BaseSection
			paddingTop={spacingTop}
			paddingBottom={spacingBottom}
			style={{
				"--color-theme-fg": galleryColorTheme
					? getColorTheme(galleryColorTheme).fg
					: "inherit",
				"--color-theme-bg": galleryColorTheme
					? getColorTheme(galleryColorTheme).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<div
				className="group relative flex overflow-hidden"
				style={{
					"--marquee-width": `-${marqueeScrollWidth}px`,
					"--marquee-multiplier-width": `${marqueeScrollWidth}px`,
					"--duration": `${marqueeScrollWidth / 100}s`,
				}}
			>
				<div
					ref={marqueeRef}
					className="animate-marquee group-hover:paused flex gap-5 whitespace-nowrap px-2.5 [&_.graphcms-image-wrapper]:contents"
				>
					<MarqueeImages
						upperLandscapeImage={upperLandscapeImage}
						upperPortraitImage={upperPortraitImage}
						lowerLandscapeImage={lowerLandscapeImage}
						lowerPortraitImage={lowerPortraitImage}
					/>
				</div>
				<div
					className="animate-marquee-multiplier group-hover:paused absolute flex gap-5 whitespace-nowrap px-2.5 [&_.graphcms-image-wrapper]:contents"
					aria-hidden="true"
				>
					{Array.from({ length: multiplier }, (_, i) => (
						<MarqueeImages
							key={i}
							upperLandscapeImage={upperLandscapeImage}
							upperPortraitImage={upperPortraitImage}
							lowerLandscapeImage={lowerLandscapeImage}
							lowerPortraitImage={lowerPortraitImage}
						/>
					))}
				</div>
			</div>
		</BaseSection>
	);
};

const MarqueeImages: React.FC<{
	upperLandscapeImage: FourImagesSectionItemFragment["upperLandscapeImage"];
	upperPortraitImage: FourImagesSectionItemFragment["upperPortraitImage"];
	lowerLandscapeImage: FourImagesSectionItemFragment["lowerLandscapeImage"];
	lowerPortraitImage: FourImagesSectionItemFragment["lowerPortraitImage"];
}> = ({
	upperLandscapeImage,
	upperPortraitImage,
	lowerLandscapeImage,
	lowerPortraitImage,
}) => {
	const resolvedUpperLandscapeImage = getFragmentData(
		CmsImageFields,
		upperLandscapeImage,
	);
	const resolvedUpperPortraitImage = getFragmentData(
		CmsImageFields,
		upperPortraitImage,
	);
	const resolvedLowerLandscapeImage = getFragmentData(
		CmsImageFields,
		lowerLandscapeImage,
	);
	const resolvedLowerPortraitImage = getFragmentData(
		CmsImageFields,
		lowerPortraitImage,
	);

	return (
		<>
			<CmsImage
				image={resolvedUpperLandscapeImage}
				alt={getImageAlt(resolvedUpperLandscapeImage)}
				className="h-[60vh] w-[80vw] rounded-xl object-cover"
			/>
			<CmsImage
				image={resolvedUpperPortraitImage}
				alt={getImageAlt(resolvedUpperPortraitImage)}
				className="h-[60vh] w-[80vw] rounded-xl object-cover"
			/>
			<CmsImage
				image={resolvedLowerPortraitImage}
				alt={getImageAlt(resolvedLowerPortraitImage)}
				className="h-[60vh] w-[80vw] rounded-xl object-cover"
			/>
			<CmsImage
				image={resolvedLowerLandscapeImage}
				alt={getImageAlt(resolvedLowerLandscapeImage)}
				className="h-[60vh] w-[80vw] rounded-xl object-cover"
			/>
		</>
	);
};
