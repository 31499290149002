"use client";

import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { colors } from "../../../styles/variables";
import BlogPostTile from "../../BlogPostTile/BlogPostTile";
import Title from "../../Title/Title";
import { ContentContainerTw } from "../../styled/ContentContainer";
import { BlogMasonry } from "../../styled/Masonry";

export const BlogPostTeaserSectionFragment = graphql(`
	fragment BlogPostTeaserSectionItem on BlogPostTeaserSection {
		id
		teaserSectionTitle: title
		teaseredBlogPosts {
			...TeaseredBlogPostItem
		}
	}
`);

export const TeaseredBlogPostItemFragment = graphql(`
	fragment TeaseredBlogPostItem on BlogPostPage {
		routeName
		slug
		locale
		headerImage {
			...CmsImageFields
		}
		publishingDate
		title
		tags
		authors: persons {
			name
		}
	}
`);

export const BlogPostTeaserSection: React.FC<{
	section: FragmentType<typeof BlogPostTeaserSectionFragment>;
	blogPostLayout?: boolean;
}> = ({ section, blogPostLayout = false }) => {
	const { teaserSectionTitle, teaseredBlogPosts } = getFragmentData(
		BlogPostTeaserSectionFragment,
		section,
	);

	return (
		<div className={blogPostLayout ? "mb-[50px]" : undefined}>
			<ContentContainerTw>
				{blogPostLayout ? (
					<Title
						headingLevel="h2"
						color={colors.almostBlack}
						textAlign="center"
					>
						{teaserSectionTitle}
					</Title>
				) : (
					<Title
						headingLevel="h1"
						color={colors.almostBlack}
						margin="auto auto 25px 0"
						breakWord
					>
						{teaserSectionTitle}
					</Title>
				)}
				{blogPostLayout ? null : (
					<div className="z-below-default mt-[-2px] border-b-2 border-gray-line" />
				)}
				<BlogMasonry>
					{teaseredBlogPosts
						.map((post) =>
							getFragmentData(TeaseredBlogPostItemFragment, post),
						)
						.map((post, index) => (
							<BlogPostTile
								key={post.slug}
								{...post}
								// show full teaser tiles only in Teaser Section
								isFullTeaser={!blogPostLayout && index === 0}
								// teaser all tiles on xs & sm breakpoints but not the md breakpoint
								isTeaserSection={!blogPostLayout}
							/>
						))}
				</BlogMasonry>
			</ContentContainerTw>
		</div>
	);
};
