import { cn } from "@peerigon/pupper/tailwind";
import { useLocale } from "next-intl";
import React, { useState } from "react";
import { useInterval } from "react-use";
import { FontStyle } from "src/__generated__/graphql";

type FigureIncreasingProps = {
	fontStyle: FontStyle;
	figure: number;
	animationRate?: number;
};

// A number that just sits there
const FigureDisplay: React.FC<Omit<FigureIncreasingProps, "animationRate">> = ({
	figure,
	fontStyle,
}) => {
	const locale = useLocale();
	const displayString = new Intl.NumberFormat(locale).format(
		Math.ceil(figure),
	);

	return (
		<span
			className={cn(
				"flex-grow",
				fontStyle.endsWith("italic") ? "italic" : undefined,
				fontStyle.startsWith("black") ? "font-bold" : "font-light",
			)}
		>
			{displayString}
		</span>
	);
};

// A number with ever increasing values (like download estimations)
const FigureIncreasing: React.FC<FigureIncreasingProps> = ({
	figure,
	fontStyle,
	animationRate = 1,
}) => {
	const fps = 30;
	const initialFigureValue =
		figure < 1000 ? Math.round(figure * 0.9) : Math.round(figure * 0.98);
	const [updatedFigure, set] = useState(initialFigureValue);

	useInterval(
		() => {
			set(updatedFigure + animationRate / fps);
		},
		updatedFigure < figure ? 1000 / fps : null,
	);

	return (
		<FigureDisplay
			figure={Math.round(updatedFigure)}
			fontStyle={fontStyle}
		/>
	);
};

export default FigureIncreasing;
